import { Link } from 'gatsby'
import React from 'react'

export default function MobileNav({ show }) {
    return (
        <nav className={show ? 'mobile-nav show': 'mobile-nav'}>
            <div className="mobile-nav__links">
                <Link to='/' activeClassName="active">Home</Link>
                <Link to='/apostille' activeClassName="active">Apostille</Link>
                <Link to='/legalisation' activeClassName="active">Legalisation</Link>
                <Link to='/fco' activeClassName="active">FCO</Link>
                <Link to='/contact' activeClassName="active">Contact</Link>
            </div>
        </nav>
    )
}