import React, { useEffect, useState } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { FiChevronsUp } from 'react-icons/fi'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import { Helmet } from 'react-helmet'
import Header from './Header'
import Footer from './Footer'
import MobileNav from './MobileNav'

import '../styles/global.css';

export default function Layout({ children }) {

    const [show, setShow] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);
    const [navOpen, setNavOpen] = useState(false)

    useEffect(() => {
        if (window.innerWidth > 800) {
            const onScroll = e => {
                setScrollPos(window.pageYOffset);
            };

            window.addEventListener("scroll", onScroll)

            if(scrollPos > 300) {
                setShow(true)
            } else {
                setShow(false)
            }
    
            return () => {
                window.removeEventListener("scroll", onScroll);
            }
        }
    }, [scrollPos])

    useEffect(() => {
        if(navOpen) {
            document.body.style.overflow ='hidden'
        } else {
            document.body.style.overflowY = 'auto'
            document.body.style.overflowX = 'hidden'
        }
    }, [navOpen])

    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    const handleShowNav = () => {
        setNavOpen(!navOpen)
    }

    return (
        <div className='layout'>
            <Helmet>
                <title>Apostille Express - Super-Fast Apostille Application Services</title>
                <meta name="keywords" content="uk apostille service, fco, application, legalisation, apostille, apostile,apostille certificate, document legalisation, hague convention, apostilles, apostille legalisation, certificates, birth, marriage, export" />
                <meta name="description" content="Document Legalisation and Apostille Services and advice including fast and easy application of documents with FCO Apostilles in the UK." />
                <meta name="google-site-verification" content="LyTFBnb_1_0zO0QPusIomPvptH3AzNPMTkZgqOzgCwE" />
            </Helmet>
            <MobileNav show={navOpen}/>
            <div className="nav__btn" onClick={handleShowNav} onKeyDown={handleShowNav} aria-hidden="true">
                    { navOpen ? <AiOutlineClose className="nav__btn--close" /> : <AiOutlineMenu /> }
            </div>
            <Header />
            <div className="content">
                { children }
            </div>
            <div className={show ? 'scroll show' : 'scroll'} onClick={scrollToTop} onKeyDown={scrollToTop} aria-hidden="true">
                <FiChevronsUp />
            </div>
            <Footer />
        </div>
    )
}
