import React from 'react'
import { BiPhone, BiMailSend, BiMap } from 'react-icons/bi'

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer__content">
                <div className="footer__box">
                    <div className="footer__contact">
                        <h3 className="heading-tertiary u-margin-bottom-medium">Contact Us</h3>
                        <a href="tel:+448453889263">
                            <BiPhone />
                                0845 388 9263
                        </a>
                        <a href="mailto:info@apostille-express.co.uk">
                            <BiMailSend />
                                info@apostille-express.co.uk
                        </a>
                        <div className="footer__location">
                            <BiMap />
                            <p className="footer__location__text">
                                Apostille Express <br/>
                                106 Old Christchurch Road <br/>
                                Bournemouth, Dorset <br/>
                                BH1 1LR
                            </p>
                        </div>
                    </div>
                </div>
                <div className="footer__box">

                </div>
                <div className="footer__box">

                </div>
            </div>
            <div className="copyright">
                This website and the Apostille service are provided by Bostico International which is part of the <a href="https://bostico.net/" target="_blank" rel="noreferrer">Bostico Group</a>.
            </div>
            <div className="copyright">
                    Copyright 2021 Apostille Express
            </div>
        </footer>
    )
}