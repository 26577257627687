import { Link } from 'gatsby'
import React from 'react'

export default function Header() {
    return (
        <header className="header">
            <div className="header__content">
                <div className="logo">
                    <Link to='/'>
                        <h1> 
                            Apostille Express
                        </h1>
                        <h2>
                            Apostille application made easy
                        </h2>
                    </Link>
                </div>
                <nav className="nav">
                    <div className="links">
                        <Link to='/' activeClassName="active">Home</Link>
                        <Link to='/apostille' activeClassName="active">Apostille</Link>
                        <Link to='/legalisation' activeClassName="active">Legalisation</Link>
                        <Link to='/fco' activeClassName="active">FCO</Link>
                        <Link to='/contact' activeClassName="active">Contact</Link>
                    </div>
                </nav>
            </div>
        </header>
    )
}
